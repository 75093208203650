/**
 * @file index.jsx
 * @description Home page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import MediumHero from "../components/mediumHero";

/**
 * @function IndexPage
 * @returns {JSX.Element} Home page.
 */

function IndexPage() {
  return (
    <>
      <Meta
        title="Home"
        description="This extension provides the “PHP-QR Code” library in WSC."
      />
      <MediumHero
        title="“PHP-QR Code” - Library"
        subtitle="This extension provides the “PHP-QR Code” library in WSC."
        buttonText="Download"
        buttonLink="/download"
      />
      <div className="section">
        <div className="container">
          <p>
            This extension provides the "PHP-QR Code" library in WSC.
            <br />
            To load the library, you can use the following code:
            <br />
            <br />
          </p>

          <code>
            require_once
            WCF_DIR.'lib/system/api/php-qrcode/vendor/autoload.php';
          </code>
          <br />
          <hr />
          <h2 className="title is-4">Quickstart</h2>
          <p>
            We want to encode this URI for a mobile authenticator into a QRcode
            image:
            <br />
            <br />
          </p>
          <pre>
            {`<?php
namespace chillerlan\\QRCode;
use chillerlan\\QRCode\\QRCode;
require_once WCF_DIR.'lib/system/api/php-qrcode/vendor/autoload.php';

$data = 'otpauth://totp/test?secret=B3JX4VCVJDVNXNZ5&issuer=felix-d1strict.de';

echo '<img src="'.(new QRCode)->render($data).'" alt="QR Code" />';`}
          </pre>
          <br />
          <figure className="image is-128x128">
            <img
              src="/images/qrcode-example.svg"
              alt="QR Code"
              loading="lazy"
              height="300"
              width="300"
            />
          </figure>
          <p>
            <br />
            More information & the documentation can be found in the
            corresponding GitHub repository:{" "}
            <a
              href="https://github.com/chillerlan/php-qrcode"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://github.com/chillerlan/php-qrcode
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default IndexPage;
