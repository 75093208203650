/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  return (
    <>
      <Meta
        title="Changelog"
        description="Get the latest news about the “PHP-QR Code” - Library."
      />
      <Hero
        title="Changelog"
        subtitle="Get the latest news about the “PHP-QR Code” - Library."
      />
      <div className="section">
        <div className="container">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <span className="tag is-medium">Version 1.0.0</span>
            </header>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag">1.0.2</span>{" "}
                  <time dateTime="2022-02-06T22:07:44+01:00">
                    6th February 2022
                  </time>
                </p>
                <p>
                  <span className="has-text-warning">&bull;</span> CI
                  fixes/updates
                  <br />
                  <span className="has-text-warning">&bull;</span> dependency
                  updates
                  <br />
                  <span className="has-text-warning">&bull;</span> cleanup
                  <br />
                  <a
                    href="https://github.com/chillerlan/php-qrcode/releases/tag/4.3.2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="has-text-info">&bull;</span> PHP-QR Code
                    4.3.2
                  </a>
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker is-primary"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag is-primary">1.0.3</span>{" "}
                  <time dateTime="2022-03-20T00:08:25+01:00">
                    20th March 2022
                  </time>
                </p>
                <p>
                  <span className="has-text-warning">&bull;</span> Fixed a bug that caused a deprecation warning for <code>strtolower()</code>. (<a href="https://github.com/chillerlan/php-qrcode/issues/97" target="_blank" rel="noreferrer">chillerlan/php-qrcode#97</a>)
                  <br />
                  <a
                    href="https://github.com/chillerlan/php-qrcode/releases/tag/4.3.2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="has-text-info">&bull;</span> PHP-QR Code
                    4.3.2
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangelogPage;
